'use strict';

angular.module('windmanagerApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('client', {
        url: '/client/:id',
        template: '<client-detail></client-detail>',
        authenticate: 'superadmin',
        data: {
          bodyClass: ''
        }
      });
  });
